<template>
  <v-container>
    <v-row class=" mt-4 justify-center ">
      <v-col
        cols="12"
        md="5"
      >
        <v-snackbar
          v-model="snackbar"
          :timeout="8000"
          top
          color="orange"
          dark
        >
          {{ text }}
          <v-btn
            color="white"
            text
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </v-snackbar>

        <v-card>
          <v-flex xs12>
            <v-toolbar
              color="primary"
              dark
            >
              <v-toolbar-title> Recuperar contraseña </v-toolbar-title>
            </v-toolbar>
          </v-flex>
        </v-card>

        <v-card
          class="mt-1 pa-3"
          align="center"
        >
          <div class="logo ">
            <img
              src="sait.png"
              width="200"
              height="80%"
              class="mb-5"
            >
          </div>
       

          <!-- EMAIL -->
          <v-flex xs12>
            <v-text-field
              v-model="email"
              solo
              autofocus
              append-icon="email"
              label="Correo"
              required
              type="text"
            />
          </v-flex>
        
          <v-divider />
        
          <v-card-actions class="mt-1 mx-3">
            <v-spacer />
            <v-btn
              small
              dark
              color="grey lighten"
              :to="{name:'login'}"
            >
              Cancelar
            </v-btn>
            <v-spacer />
            <v-btn
              small
              color="red darken-1"
              dark
              class="elevation-5"
              @click="ValidarCorreo"
            >
              Recordar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import router from '@/router'
// import {mapActions , mapGetters} from 'vuex'
export default {
  data(){
    return{
      email:'',
      id   : '',
      Nomuser: '',

      snackbar: false,
      y: 'top',
      x: null,
      mode: '',
      timeout: 8000,
      text: '',
      logo:'https://soportesait.com/soporte1.png',


    }
  },


  methods:{

    ValidarCorreo(){
      if(this.email == ''){
        this.snackbar = true ; this.text = "Capture su correo electronico"
        return
      }

      const payload= {  Email: this.email }

      console.log("payload", payload)
      return new Promise((resolve) => {
        
        this.$http.post('olvidacontra', payload) .then(respuesta=>{
          return respuesta.json()
          // console.log("olvidacontra", respuesta)
        }).then(respuestaJson=>{

          if(respuestaJson === null){
            resolve(false)
            this.snackbar= true ; this.text="Esté correo no es valido." ; this.timeout=3000
          }else{

            console.log(respuestaJson)
            let id = respuestaJson[0].idusuariosweb

            let curl = 'https://soportesait.com'
            
            // let curl = 'http://localhost:8080'
            // curl = this.$http.options.root
         

            console.log("curl" + "/cambiacontra/" + id)
            
            const payload = {
              Idusuariosweb  : respuestaJson[0].idusuariosweb,
              Nomuser        : respuestaJson[0].nomuser,
              Email          : respuestaJson[0].email,
              logo            : this.logo,
              color           : this.$vuetify.theme.themes.light.primary,
              ruta            : curl + "/cambiacontra/" + id
            }

            this.Enviarcorreo(payload)
            resolve(true)
          }

        }).catch(error=>{console.log('error',error)})
      })
          
    },

    Enviarcorreo (payload){
      console.log("payload EnviarCorreo",payload)
      this.$http.post('sendolvidacontra',payload).then(function(response){
        if(response.status == 200){

          this.color = 'success'
          this.snackbar = true
          this.text = 'Se ha enviado el correo de validacion a su cuenta.'
          this.to=''
          this.subject=''
          this.cuerpo=''
        }else{
          this.color = 'red'
          this.snackbar = true
          this.text = 'Falla al enviar el correo'
          this.to=''
          this.subject=''
          this.cuerpo=''

        }

        // var me = this
        // Mandar a login
        setTimeout(()=>{ this.$router.push({name: 'login'}).catch(() => {})  }, 3000);
      }).catch((error) => {console.log(error)})
    },
  }
};


</script>

<style scoped>
  
  .logo{
    align-content: "center";
    text-align: center;

  }
</style>
